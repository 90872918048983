<template>
    <div class="page1">
        <EForm :formColumns="formColumns" :rowSize="4" :optionsBtn="true" :actionBtn="false" :formData="searchForm" ref="form" @onSearch="onSearch" :searchFlag="true" :exportShow="false" >
        </EForm>
        <EButton type="primary" @click="dialogVisible=true">新增</EButton>
        <ETable :tableTitle="tableTitle" :tableData="tableData"
                :needPagination="true" :count="count"
                @changePage="changePage"
                @changeSize="changeSize"
                :page="searchForm.current" :page_size="searchForm.size">
            <el-table-column fixed="right" label="操作" width="180px">
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="showDialog(scope.row)">
                            修改
                        </EButton>
                        <EButton type="text" @click="remove(scope.row)">
                            删除
                        </EButton>
                        <EButton type="text" @click="change(scope.row.boothInformationId,scope.row.status)">
                            {{scope.row.status==1?'禁用':'启用'}}
                        </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="dialogForm.boothInformationId?'修改摊位':'新增摊位'" @handleClose="cancelDialog" width="50%" @handleClick="saveData(dialogForm.boothInformationId)"
        :disabled="saveDisabled">
            <el-row :gutter="10">
                <el-form size="small" label-position="'right'" label-width="120px" :model="dialogForm" :rules="formRules" ref="form">
                    <el-col :span="12">
                        <el-form-item label="摊位编号：" prop="boothCode">
                            <el-input v-model="dialogForm.boothCode" placeholder="摊位类型"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="面积（m²）：" prop="areas">
                            <el-input v-model="dialogForm.areas" placeholder="摊位类型"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="区域：" prop="areaId">
                            <el-select placeholder="请选择" v-model="dialogForm.areaId" class="ws">
                                <el-option v-for="item in areaOptions" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="摊位用途：" prop="boothUseId">
                            <el-select placeholder="请选择" v-model="dialogForm.boothUseId" class="ws">
                                <el-option v-for="item in boothUseOptions" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="位置：" prop="placeId">
                            <el-select placeholder="请选择" v-model="dialogForm.placeId" class="ws">
                                <el-option v-for="item in locationOptions" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="摊位类型：" prop="boothTypeId">
                            <el-select placeholder="请选择" v-model="dialogForm.boothTypeId" class="ws">
                                <el-option v-for="item in boothTypeOptions" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="说明：" prop="remark">
                            <el-input type="textarea" v-model="dialogForm.remark" placeholder="说明"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import { identity_type, status } from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data () {
      return {
        tableTitle: [
          {
            label: '摊位编号',
            prop: 'boothCode'
          },
          {
            label: '摊位名称',
            prop: 'boothName'
          },
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '面积（平方米）',
            prop: 'areas'
          },
          {
            label: '摊位用途',
            prop: 'boothUseName'
          },
          {
            label: '摊位类型',
            prop: 'boothTypeName'
          },
          {
            label: '租否',
            prop: 'isRentCn'
          },
          {
            label: '商户',
            prop: 'tenantName'
          },
          {
            label: '新建时间',
            prop: 'createTime'
          },
          {
            label: '新建人',
            prop: 'createUserCn'
          },
          {
            label: '修改时间',
            prop: 'updateTime'
          },
          {
            label: '修改人',
            prop: 'updateUserCn'
          },
          {
            label: '状态',
            prop: 'statusCn'
          },
          {
            label: '备注',
            prop: 'remark'
          },
        ],
        formColumns: [
          {
            title: '摊位编号',
            type: 'text',
            property: 'boothCode',
            show: true
          },
          {
            title: '租否',
            type: 'select',
            property: 'isRent',
            show: true,
          },
          {
            title: '修改人 ',
            type: 'text',
            property: 'updateUser',
            show: true
          },
          {
            title: '新建人',
            type: 'text',
            property: 'createUser',
            show: true
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options: []
          },
          {
            title: '摊位用途',
            type: 'select',
            property: 'boothUseId',
            show: true,
            options: []
          },
          {
            title: '修改时间',
            type: 'datePicker',
            property: 'updateTime',
            show: true
          },
          {
            title: '新建时间',
            type: 'datePicker',
            property: 'createTime',
            show: true
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: []
          },
          {
            title: '摊位类型',
            type: 'select',
            property: 'boothTypeId',
            show: true,
            options: []
          },
        ],
        tableData: [
        ],
        searchForm: {
          boothCode: null,
          isRent: null,
          updateUser: null,
          createUser: null,
          areaId: null,
          boothUseId: null,
          updateTime: null,
          createTime: null,
          placeId: null,
          boothTypeId: null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formRules: {
          boothCode: vxRule(true, '', "blur", "摊位编号不能为空"),
          areas: vxRule(true, '', "blur", "面积不能为空"),
          areaId: vxRule(true, '', "change", "区域不能为空"),
          boothUseId: vxRule(true, '', "change", "摊位用途不能为空"),
          placeId: vxRule(true, '', "change", "位置不能为空"),
          boothTypeId: vxRule(true, '', "change", "摊位类型不能为空"),
          remark: vxRule(true, '', "blur", "说明不能为空"),
        },
        dialogForm: {
          boothInformationId: '',
          boothCode: '',
          areas: '',
          areaId: '',
          boothUseId: '',
          placeId: '',
          boothTypeId: '',
          remark: '',
        },
        options: [],
        form: {},
        areaOptions: [],
        locationOptions: [],
        boothUseOptions: [],
        boothTypeOptions: [],
      }
    },
    watch: {},
    created () {
      this.getData()
      this.getPlaceLabel()
      this.getAreaLabel()
      this.boothTypeLabel()
      this.boothUseLabel()
    },
    components: { ETable, EDialog, EButton, EForm },
    beforeMount () {

    },
    methods: {
      async changeStatus(id,status){
        let res=await Http.cmboothinformationUpdStatus({id,status})
        if(res.code==200){
          this.$message.success(res.msg)
          this.getData()
        }
      },
      change(id, status) {
        let msg = ''
        if (status == 1) {
          msg = '禁用'
        } else {
          msg = '启用'
        }
        this.$messageBox.confirm('确定' + msg + '？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          this.changeStatus(id, status==1?0:1)
        }).catch(res => {

        });
      },
      saveData (boothInformationId) {
        this.$refs['form'].validate((valid, object) => {
          console.log(object)
          if (valid) {
            this.boothInfoSubmit(boothInformationId)
          } else {
            return false;
          }
        })
      },
      onSearch () {
        console.log(this.searchForm.boothCode)
        this.searchForm.current = 1
        this.getData()
      },
      changePage (current) {
        this.searchForm.current = current
        this.getData()
      },
      changeSize(size){
        this.searchForm.size = size
        this.getData()
      },
      cancelDialog () {
        this.dialogVisible = false
        this.dialogForm = {
          boothInformationId: '',
          boothCode: '',
          areas: '',
          areaId: '',
          boothUseId: '',
          placeId: '',
          boothTypeId: '',
          remark: '',
        }
        this.$refs.form.resetFields();
      },
      showDialog (row) {
        console.log(row)
        this.dialogForm = {
          boothInformationId: row.boothInformationId,
          boothCode: row.boothCode,
          areas: row.areas,
          areaId: row.areaId,
          boothUseId: row.boothUseId,
          placeId: row.placeId,
          boothTypeId: row.boothTypeId,
          remark: row.remark,
        }
        this.dialogVisible = true
      },
      async getData () {
        let res = await Http.getBoothInfoList(this.searchForm)
        if (res.code == 200) {
          this.tableData = res.data.records
          this.count = res.data.total
        }
      },
      //新增或者修改请求
      async boothInfoSubmit (boothInformationId) {
        this.setDisabled(true)
        let params = {
          boothCode: this.dialogForm.boothCode,
          areas: this.dialogForm.areas,
          areaId: this.dialogForm.areaId,
          boothUseId: this.dialogForm.boothUseId,
          placeId: this.dialogForm.placeId,
          boothTypeId: this.dialogForm.boothTypeId,
          remark: this.dialogForm.remark,
        }
        if (boothInformationId) {
          params.boothInformationId = boothInformationId
        }
        let res = await Http.boothInfoSubmit(params)
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.dialogVisible = false
          this.setDisabled(false)
          this.cancelDialog()
          this.getData()
        }
      },
      remove (row) {
        this.$messageBox.confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.boothInfoRemove(row.boothInformationId)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      async boothInfoRemove (boothInformationId) {
        let res = await Http.boothInfoRemove({ ids: boothInformationId })
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },
      //获取位置下拉
      async getPlaceLabel () {
        let res = await Http.getPlaceLabel()
        if (res.code == 200) {
          this.formColumns[8].options = res.data
          this.locationOptions = res.data
        }
      },
      //获取区域下拉
      async getAreaLabel () {
        let res = await Http.getAreaLabel()
        if (res.code == 200) {
          this.formColumns[4].options = res.data
          this.areaOptions = res.data
        }
      },
      //摊位类型下拉
      async boothTypeLabel () {
        let res = await Http.boothTypeLabel()
        if (res.code == 200) {
          this.formColumns[9].options = res.data
          this.boothTypeOptions = res.data
        }
      },
      //摊位用途下拉
      async boothUseLabel () {
        let res = await Http.boothUseLabel()
        if (res.code == 200) {
          this.formColumns[5].options = res.data
          this.boothUseOptions = res.data
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
    .ws {
        width: 100%;
    }
</style>
